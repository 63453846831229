
<template>
  <div id="recruit-chat">
    <v-progress-circular
      class="ma-3"
      v-if="loadingData"
      :size="50"
      color="primary darken-1"
      indeterminate
    ></v-progress-circular>
    <ChatMode
      v-if="ready"
      :override="override"
      :upil="upil"
      key="Template"
      :avatar="TruffleLogo"
      @update:currentEvent="currentEvent = $event"
      :types="types"
      :transformReplyVariables="transformReplyVariables"
      :transformTextVariables="transformTextVariables"
      locale="ja"
      :i18n="i18n"
      :botTypingDurationInMsPerMessage="400"
    />
  </div>
</template>

<script>
import { UPILCore } from '@appsocially/userpil-core'
import { ChatBot, setupListeners } from '@appsocially/vue-upil'
import { override } from '@/components/override/recruit'
import script from '@/upil-scripts/recruit'
import TruffleLogo from '@/assets/truffle_logo.png'
import { listeners } from '@/listeners/recruit'
import types from '@/helpers/types'
import { createNamespacedHelpers } from 'vuex'
const { mapState: authState } = createNamespacedHelpers('auth')

const { ChatMode } = ChatBot

const padTime = (timeNum) => `${timeNum}`.padStart(2, 0)

const formatTimeInputValue = (timeInputValue) => {
  return `${padTime(timeInputValue.hours)}:${padTime(timeInputValue.minutes)}`
}

const transformReplyVariables = ({
  node: {
    event: { value },
    label
  }
}) => {
  if (label === 'time-input') {
    return formatTimeInputValue(value)
  } else if (Array.isArray(value)) {
    return value.join('、')
  } else {
    return value
  }
}

const transformTextVariables = ({ value, key, locale }) => {
  if (key === 'timeRangeStart' || key === 'timeRangeEnd') {
    return formatTimeInputValue(value, locale)
  } else {
    return value
  }
}

export default {
  components: {
    ChatMode
  },
  data () {
    return {
      upil: null,
      TruffleLogo,
      override,
      eventsWithLabels: null,
      types,
      currentEvent: null,
      i18n: {
        ja: {
          missingValue: '未記入',
          templateInputPlaceholder: '入力してください',
          selectInputPlaceholder: '選んでください',
          multiSelectInputPlaceholder: '選んでください'
        }
      }
    }
  },
  computed: {
    ...authState(['uid']),
    loadingData () {
      const hasNode = this.currentEvent && this.currentEvent.event && this.currentEvent.event.node
      const isEndEvent = hasNode && this.currentEvent.event.isEndEvent
      const isExternal = hasNode && this.currentEvent.event.node.type === 'external'
      const isScenario = hasNode && this.currentEvent.event.node.type === 'scenario' && !isEndEvent
      return isExternal || isScenario
    },
    ready () {
      return this.upil
    }
  },
  watch: {
    uid: {
      immediate: true,
      handler (uid) {
        if (uid) {
          this.start()
        }
      }
    }
  },
  methods: {
    start () {
      const upil = new UPILCore()
      setupListeners({ upil, listeners: listeners(this.uid) })
      upil.startRaw(script)
      this.upil = upil
    },
    transformReplyVariables,
    transformTextVariables
  }
}
</script>

<style scoped>
#recruit-chat >>> .upil-select .v-input__control,
#recruit-chat >>> .upil-multi-select .v-input__control {
  background-color: #424242;
  padding-left: 5px;
}

#recruit-chat >>> .dynamic-list-builder-chip {
  background-color: var(--v-primary-base);
}

#recruit-chat >>> .bubble-container {
  margin: 0;
  padding: 0;
}
</style>
