<template>
  <transition name="fade" appear>
    <Chat />
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import Chat from '@/components/Chat/recruit'
import { setAsOwner, EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
// import { isOnBoarded } from '@/helpers/firebase-functions'
import { getUserInfo } from '@/helpers/user-info'
import { createStripeInvoiceCustomer, createStripeInvoiceSubscription } from '@/helpers/firebase-functions'

const style = 'margin: 0; height: 100%; overflow: hidden;'

export default {
  metaInfo: {
    htmlAttrs: {
      style
    },
    bodyAttrs: {
      style
    }
  },
  components: {
    Chat
  },
  data: () => ({
    idempotencyKey: '',
    isOnBoarded: false,
    showDrawer: false
  }),
  computed: {
    ...mapState('auth', ['uid', 'isOnboardingDone'])
  },
  methods: {
    async createStripeInvoiceCustomerIfRequired (ownerId) {
      const info = await getUserInfo(ownerId)
      if (info) {
        const { data: customer } = await createStripeInvoiceCustomer(this.idempotencyKey)
        await createStripeInvoiceSubscription({ customer: customer.id, idempotencyKey: this.idempotencyKey })
      }
    }
  },
  async created () {
    this.idempotencyKey = `${this.uid}${new Date().getTime()}`
    await this.createStripeInvoiceCustomerIfRequired(this.uid)

    setAsOwner()
    // const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    // const { data: { result: isOnBoarded_ } } = await isOnBoarded({ ownerId: this.uid, scenarioId: ownerScenarioId })
    const isOnBoarded_ = this.isOnboardingDone
    this.isOnBoarded = isOnBoarded_
    this.showDrawer = isOnBoarded_
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_RECRUIT)
  }
}

</script>
